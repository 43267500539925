import { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import classNames from 'clsx';

const styles = {
  container: {
    maxWidth: 640,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  listItemPlaceholder: {
    paddingTop: 56,
    paddingBottom: 200,
  },
  paddingTop10: {
    paddingTop: 10,
  },
  paddingBottom10: {
    paddingBottom: 10,
  },
  avatarPlaceholder: {
    background: 'rgba(0, 0, 0, .05)',
    '-webkit-border-radius': '100%',
    borderRadius: '100%',
    float: 'left',
    marginRight: 10,
    marginBottom: 10,
  },
  size36x36: {
    width: 36,
    height: 36,
  },
  size32x32: {
    width: 32,
    height: 32,
  },
  floatLeftPlaceholder: {
    float: 'left',
    paddingTop: 2,
  },
  linePlaceholder: {
    background: 'rgba(0, 0, 0, .05)',
    display: 'inline-block',
    height: 10,
    marginTop: 7,
    marginBottom: 7,
    width: '100%',
    float: 'left',
    clear: 'both',
  },
  linePlaceholderMedium: {
    width: 240,
  },
  linePlaceholderSmall: {
    width: 120,
  },
  linePlaceholderSmaller: {
    width: 80,
  },
  linePlaceholderThin: {
    height: 8,
    marginTop: 4,
    marginBottom: 4,
  },
  linePlaceholderTruncated: {
    width: '80%',
  },
  shimerInfinite: {
    animation: 'shimmer 1.2s ease-in-out infinite',
    animationName: '$shimmer',
  },
  '@keyframes shimmer': {
    '0%': {
      opacity: 0.8,
    },

    '50%': {
      opacity: 0.5,
    },

    '100%': {
      opacity: 0.8,
    },
  },
  containerLeft: {
    marginRight: 24,
    flex: '1 1 auto',
  },
  containerRight: {
    flex: '0 0 auto',
    width: 152,
  },
  '@media screen and (max-width: 855px)': {
    containerRight: {
      width: 140,
    },
  },
  '@media screen and (max-width: 503px)': {
    containerRight: {
      width: 80,
      height: 72,
    },
    linePlaceholderMedium: {
      maxWidth: 140,
    },
    linePlaceholderSmall: {
      width: 80,
    },
    linePlaceholderSmaller: {
      width: 40,
    },
  },
  '@media screen and (max-width: 350px)': {
    linePlaceholderMedium: {
      maxWidth: 100,
    },
    linePlaceholderSmall: {
      width: 50,
    },
    linePlaceholderSmaller: {
      width: 25,
    },
  },
  imagePlaceholder: {
    backgroundColor: '#F0F0EE',
    width: '100%',
    height: '100%',
  },
  flex: {
    display: 'flex',
  },
};

type OwnProps = {
  classes: {
    [key: string]: any;
  };
  type?: string;
  size?: string;
  useSeparator?: boolean;
  withContent?: boolean;
};
const defaultProps = {
  type: 'normal',
  size: 'medium',
  useSeparator: true,
  withContent: true,
};
type Props = OwnProps & typeof defaultProps;

// @ts-expect-error ts-migrate(1238) FIXME: Unable to resolve signature of class decorator whe... Remove this comment to see the full error message
@withStyles(styles, { name: 'WMPublicationsPlaceholderLoading' })
export default class PlaceholderLoading extends Component<Props> {
  static defaultProps = defaultProps;

  render() {
    const { classes, type, size, useSeparator, withContent } = this.props;

    if (type === 'stream') {
      return (
        <div className={classNames('clearfix', classes.shimerInfinite)}>
          <div
            className={classNames(classes.flex, {
              [classes.listItemPlaceholder]: useSeparator,
            })}
          >
            <div className={classes.containerLeft}>
              {!!withContent && (
                <>
                  <div className={classNames('clearfix', classes.linePlaceholder)} />
                  <div className={classNames('clearfix', classes.linePlaceholder)} />
                  <div
                    className={classNames(
                      'clearfix',
                      classes.linePlaceholder,
                      classes.linePlaceholderTruncated
                    )}
                  />
                </>
              )}
              <div className="clearfix" />
              <div
                className={classNames('clearfix', classes.paddingTop10, classes.paddingBottom10)}
              >
                <div
                  className={classNames(classes.avatarPlaceholder, {
                    [classes.size36x36]: size === 'medium',
                    [classes.size32x32]: size === 'small',
                  })}
                />
                <div className={classes.floatLeftPlaceholder}>
                  <div
                    className={classNames(classes.linePlaceholder, classes.linePlaceholderThin, {
                      [classes.linePlaceholderMedium]: size === 'medium',
                      [classes.linePlaceholderSmall]: size === 'small',
                    })}
                  />
                  <div
                    className={classNames(classes.linePlaceholder, classes.linePlaceholderThin, {
                      [classes.linePlaceholderSmall]: size === 'medium',
                      [classes.linePlaceholderSmaller]: size === 'small',
                    })}
                  />
                </div>
              </div>
            </div>
            <div className={classes.containerRight}>
              <div className={classes.imagePlaceholder} />
            </div>
          </div>
        </div>
      );
    }

    if (size === 'title') {
      return (
        <div className={classNames('clearfix', classes.container, classes.shimerInfinite)}>
          <div className="clearfix">
            <div
              className={classNames(
                'clearfix',
                classes.linePlaceholder,
                classes.linePlaceholderThin,
                classes.linePlaceholderSmall
              )}
            />
          </div>
        </div>
      );
    }

    return (
      <div className={classNames('clearfix', classes.container, classes.shimerInfinite)}>
        <div
          className={classNames('clearfix', {
            [classes.listItemPlaceholder]: useSeparator,
          })}
        >
          <div className={classNames('clearfix', classes.paddingBottom10)}>
            <div
              className={classNames(classes.avatarPlaceholder, {
                [classes.size36x36]: size === 'medium',
                [classes.size32x32]: size === 'small',
              })}
            />
            <div className={classes.floatLeftPlaceholder}>
              <div
                className={classNames(classes.linePlaceholder, classes.linePlaceholderThin, {
                  [classes.linePlaceholderMedium]: size === 'medium',
                  [classes.linePlaceholderSmall]: size === 'small',
                })}
              />
              <div
                className={classNames(classes.linePlaceholder, classes.linePlaceholderThin, {
                  [classes.linePlaceholderSmall]: size === 'medium',
                  [classes.linePlaceholderSmaller]: size === 'small',
                })}
              />
            </div>
          </div>
          {!!withContent && (
            <>
              <div className={classNames('clearfix', classes.linePlaceholder)} />
              <div className={classNames('clearfix', classes.linePlaceholder)} />
              <div
                className={classNames(
                  'clearfix',
                  classes.linePlaceholder,
                  classes.linePlaceholderTruncated
                )}
              />
            </>
          )}
        </div>
      </div>
    );
  }
}
