import config from 'config';
import type { I18nStories } from '../../api/database/Models';

const { domain } = config;

const getLink = (post: string | I18nStories, relative = true) => {
  let base = '';
  if (!relative) {
    base = domain;
  }
  if (typeof post === 'string') {
    return base + post;
  }
  const { Publication: pub, parent, User } = post;
  const isResponse = post.isResponse ? parseInt(post.isResponse, 10) : 0;
  if ((!parent || !parent.slug) && pub && pub.slug) {
    return `${base}/${pub.slug}/${post.slug}-${post.uid}`;
  }
  if ((parent && parent.slug) || isResponse === 1) {
    return `${base}/@${User.username}/${post.slug}-${post.uid}`;
  }
  // return `${base}/s/story/${post.slug}-${post.uid}`;
  return `${base}/@${User.username}/${post.slug}-${post.uid}`;
};

export default getLink;

const getLinkPublication = (pub: any, post: { uid: any; slug: any }, relative = true) => {
  const obj: I18nStories = { uid: post.uid, slug: post.slug, Publication: pub } as I18nStories;
  return getLink(obj, relative);
};

export { getLinkPublication, getLink };
